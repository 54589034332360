'use strict';

import CmpVendorScriptLoader from './components/CmpVendorScriptLoader';
import NavigationHandler from './components/NavigationHandler';
import CommonMethods from './components/CommonMethods';
import TrackingService from './components/TrackingService';
import Offcanvas from './components/Offcanvas';
import RoughNotations from './components/RoughNotations';
import Footer from './components/Footer';
import Tabnav from './components/Tabnav';
import BrushHeadlines from './components/BrushHeadlines';
import Slider from './components/Slider';
import Tooltip from './components/Tooltip';
import Tabs from './components/Tabs';
import AudioPlayer from './components/AudioPlayer';
import WebShareUI from './components/WebshareUI';
import LoadMoreCards from './components/LoadMoreCards';
import Clipboard from './components/Clipboard';
import Dialog from './components/Dialog';
import User from './components/User';
import Emitter from './components/Emitter';
import Accordion from './components/Accordion';
import ChannelService from './components/ChannelService';
import ChannelCurrentlyPlaying from './components/ChannelCurrentlyPlaying';
import MetadataService from './components/MetadataService';
import VideoPlayer from './components/VideoPlayer';
import StationSelectDialog from './components/StationSelectDialog';
import Traffic from './components/Traffic';
import BookmarkService from './components/BookmarkService';
import Embed from './components/Embed';
import ScrollText from './components/ScrollText';
import GeolocationWidget from './components/GeolocationWidget';
import Newsticker from './components/Newsticker';
import Webcam from './components/Webcam';
import Quiz from './components/Quiz';
import ShowAll from './components/ShowAll';
import Form from './components/Form';
import WeatherService from './components/WeatherService';
import WeatherRadar from './components/WeatherRadar';
import Reklame from './components/Reklame';
import { OldieAntenneColorRotation } from './components/OldieAntenneColorRotation';

// Fallbacks for JS Bridge Navive/Webview
window.nativeJsBridge = window.nativeJsBridge || {
    isWebview: false,
    async callHandlerIfWebview () {},
    callHandler (a, b) {
        // eslint-disable-next-line max-len
        throw new Error('This is not a webview! Before calling callHandler, please check `window.nativeJsBridge.isWebview`!');
    },
};
window.webviewJsBridge = window.webviewJsBridge || { addHandler: (a, b) => { } };

window.webviewJsBridge.addHandler('media.stop', async () => {
    await Promise.allSettled([
        audioPlayer.pause(),
        videoPlayer.pauseVideos(),
    ]);
});

// This needs to be first
const eventEmitter = new Emitter();
// Init Common Services
const commonMethods = new CommonMethods(eventEmitter);
// Global Tracking Service
const trackingService = new TrackingService(commonMethods);

const channelService = new ChannelService();
const metadataService = new MetadataService(eventEmitter, commonMethods);

if (
    typeof window.loadedlog === 'object' &&
    window.loadedlog !== null &&
    window.console
) {
    window.console.log('ANTENNE JS loaded', window.loadedlog);
}
// Dispatch a global event when the main js has been loaded
window.dispatchEvent(new CustomEvent('antenne.jsloaded'));

// Load Scripts based on CMP-Consents
new CmpVendorScriptLoader(commonMethods);

// Handle Navigation between pages (Turbo)
const navigationHandler = new NavigationHandler(commonMethods, trackingService, eventEmitter);
// Delay emetriq script loading
navigationHandler.on('ready', () => setTimeout(() => trackingService.loadEmetriq(), 1000));

const slider = new Slider(commonMethods, navigationHandler);
const webShareUI = new WebShareUI(navigationHandler, trackingService);
const tooltip = new Tooltip(navigationHandler);
const channelCurrentlyPlaying = new ChannelCurrentlyPlaying(
    eventEmitter,
    navigationHandler,
    channelService,
    metadataService,
);
const bookmarkService = new BookmarkService(
    commonMethods,
    eventEmitter,
    trackingService,
    slider,
    tooltip,
    webShareUI,
    channelCurrentlyPlaying,
);

new Offcanvas(navigationHandler);

const dialog = new Dialog(commonMethods, navigationHandler, eventEmitter, slider);
const user = new User(commonMethods, navigationHandler, eventEmitter, dialog, trackingService, bookmarkService);

new StationSelectDialog(commonMethods, navigationHandler, eventEmitter, dialog);

new LoadMoreCards(navigationHandler);
new Tabs(navigationHandler);
new BrushHeadlines(commonMethods, navigationHandler);
new Accordion(navigationHandler);
new ScrollText(commonMethods, navigationHandler);
new RoughNotations(navigationHandler);
new Tabnav(commonMethods, navigationHandler);
new Footer(commonMethods, navigationHandler);
new ShowAll(navigationHandler);
new Webcam(navigationHandler);

new Clipboard(navigationHandler);
new Quiz(commonMethods, navigationHandler);
new Form(commonMethods, navigationHandler);

const videoPlayer = new VideoPlayer(trackingService, eventEmitter, navigationHandler, commonMethods);
const audioPlayer = new AudioPlayer(
    commonMethods,
    trackingService,
    tooltip,
    eventEmitter,
    metadataService,
    channelService,
    user,
    navigationHandler,
);

new Traffic(commonMethods, navigationHandler);
new GeolocationWidget(commonMethods, navigationHandler, dialog);
new Embed(commonMethods, navigationHandler, eventEmitter);
new Newsticker(commonMethods, navigationHandler);
new WeatherService(navigationHandler, commonMethods);
new WeatherRadar(navigationHandler, commonMethods);

// dispatched sync "finish" event
window.dispatchEvent(new CustomEvent('antenne.jsexecuted'));

document.documentElement.classList.remove('u-no-js');
document.documentElement.classList.add('u-supports-js');
OldieAntenneColorRotation(navigationHandler);

// Reklame SHOULD always be last component!
new Reklame(commonMethods, navigationHandler, eventEmitter, trackingService, user);

// Header SVG Animation
commonMethods.isDomReady().then(() => {
    const svg = document.querySelector('.l-page-header__background');
    if (svg && svg.parentElement.classList.contains('is-fallback-background') === false) {
        const objects = svg.querySelectorAll('[data-transformlimits]');
        if (!objects) {
            return;
        }

        const getRandomFloat = (min, max) => {
            min = parseFloat(min);
            max = parseFloat(max);
            return Math.floor((Math.random() * (max - min) + min));
        };

        const setTranslate = (object) => {
            const [x, y] = object.dataset.transformlimits.split(';', 2);
            const [xMin, xMax] = x.split(',', 2);
            const [yMin, yMax] = y.split(',', 2);
            // object.style.transition = 'transform ease-in-out ' + duration + 's';
            // eslint-disable-next-line max-len
            object.style.setProperty('--translate', getRandomFloat(xMin, xMax) + 'px, ' + getRandomFloat(yMin, yMax) + 'px');
        };

        window.animateHeader = () => {
            objects.forEach(object => {
                setTranslate(object);
            });
        };

        navigationHandler.on('before-fetch', () => {
            window.animateHeader();
        });
    }
});
