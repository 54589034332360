'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 * @typedef {import('./CommonMethods').default} CommonMethods
 */

import ClassLogger from 'ClassLogger';
import Confetti from 'canvas-confetti';

class Quiz {
    getClassName () {
        return 'Quiz';
    }

    /**
     * Create a new instance.
     * @param {NavigationHandler} navigationHandler
     * @param {CommonMethods} commonMethods
     */
    constructor (commonMethods, navigationHandler) {
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.navigationHandler = navigationHandler;
        this.commonMethods = commonMethods;

        this.navigationHandler
            .on('ready', () => this.init())
            .on('render', () => this.init());
    }

    init () {
        const quizzes = document.querySelectorAll('.c-quiz');

        quizzes.forEach((quizElement) => {
            if (quizElement.classList.contains('c-quiz--schlaubayeroftheday')) {
                this.initSchlaubayerOfTheDay(quizElement);
                return;
            }
            const quizConfigObject = JSON.parse(quizElement.querySelector('.quizConfig').textContent);
            if (!quizConfigObject) {
                this.logger.log('No quizConfig found');
                return;
            }
            const button = quizElement.querySelector('.c-button');
            const coverSlide = quizElement.querySelector('.c-quiz__slide--cover');

            let form = null;
            if (quizConfigObject.config.promotion_form.id) {
                form = document.querySelector(`[data-quizformwrapper="${quizConfigObject.id}"]`);
                form.classList.add('u-hide'); // also done in HTML too
            }

            coverSlide.addEventListener('click', () => {
                this.buildSlides(quizElement, quizConfigObject, form);
            });
            button.addEventListener('click', (event) => {
                event.stopPropagation();
                this.buildSlides(quizElement, quizConfigObject, form);
            }, { once: true });
        });
    }

    initSchlaubayerOfTheDay (quizElement) {
        this.maxAnswerTime = 20;
        const quizSlide = quizElement.querySelector('.c-quiz__slide');
        this.initCountdown(quizSlide);

        const answerElements = quizElement.querySelectorAll('.c-quiz__button-answer');
        const buttonNext = quizElement.querySelector('.c-quiz__button-next');

        // shuffle answers
        const answerParent = answerElements[0].parentNode;
        for (let i = answerParent.children.length; i >= 0; i--) {
            answerParent.appendChild(answerParent.children[Math.random() * i | 0]);
        }

        answerElements.forEach((button) => {
            button.classList.remove('u-background-transparent');
            button.addEventListener('click', () => {
                if (buttonNext) {
                    buttonNext.classList.remove('u-disabled');
                }

                // disable all other buttons
                answerElements.forEach((button) => {
                    button.disabled = true;
                    button.classList.add('u-disabled');
                });
                button.style.opacity = 1;

                // adding background and animation and incrementing counter if correct answer was given
                if ('correct' in button.dataset) {
                    button.classList.add('u-background-green');
                    button.classList.add('u-jello-horizontal');
                    this.fireworks(2);
                } else {
                    button.classList.add('u-background-red');
                    button.classList.add('u-shake-horizontal');

                    answerElements.forEach((button) => {
                        if ('correct' in button.dataset) {
                            button.classList.add('u-background-green');
                            button.classList.add('u-jello-horizontal');
                        }
                    });
                }
            });
        });
    }

    buildSlides (quizElement, quizConfigObject, form = null) {
        let questions = quizConfigObject.questions;

        // get quiz config and set defaults if needed
        const maxQuestions = parseInt(quizConfigObject.config.maxQuestionsShown
            ? quizConfigObject.config.maxQuestionsShown
            : questions.length);
        this.maxAnswerTime =
            parseInt(quizConfigObject.config.timePerAnswer ? quizConfigObject.config.timePerAnswer : 0);
        const showSolution = quizConfigObject.config.showCorrectAnswers
            ? quizConfigObject.config.showCorrectAnswers
            : 0;
        const shuffleQuestions =
        quizConfigObject.config.shuffleQuestions ? quizConfigObject.config.shuffleQuestions : 0;
        const resultMessage = quizConfigObject.config.resultMessage
            ? quizConfigObject.config.resultMessage
            : 'Du hast CORRECT_ANSWERS von TOTAL_QUESTIONS Fragen richtig beantwortet.';

        if (shuffleQuestions) {
            questions = questions.sort(() => Math.random() - 0.5);
        }

        // limit number of questions
        if (maxQuestions < questions.length) {
            questions = questions.slice(0, maxQuestions);
        }

        // init correct answer counter
        let counter = 0;

        questions.forEach((slideData, index) => {
            // build answer html
            let answers = [];
            slideData.wrong_answers.forEach((answer) => {
                answers.push(`
                    <button class="c-quiz__button-answer c-button c-button--fullwidth">${answer}</button>
                `);
            });

            slideData.correct_answers.forEach((answer) => {
                answers.push(`
                    <button class="c-quiz__button-answer c-button c-button--fullwidth" data-correct>${answer}</button>
                `);
            });

            // randomize answer positions
            answers = answers.sort(() => Math.random() - 0.5);

            // add image html, if there is one in the config
            let image = '';
            if (slideData.image) {
                image = `
                    <figure class="c-image u-extra-small-margin--top" style="--aspectratio:16 / 9;">
                        <img src="${slideData.image}" loading="lazy" width="996" height="560">
                    </figure>`;
            }

            // button text is different on last slide of quiz
            const buttonText = index + 1 !== questions.length ? 'Nächste Frage' : 'Ergebnis anzeigen';

            const slide = this.commonMethods.markupToElement(`
                <div class="c-quiz__slide" data-idx="${index}">
                    <div class="c-quiz__slide-content">
                    ${image}
                        <p class="o-headline--size2 u-no-margin--top">${slideData.question}</p>
                        <div class="c-quiz__answers">${answers.join(' ')}</div>
                        <p><i> Frage ${index + 1} von ${questions.length}</i></p>
                        <button class="c-quiz__button-next c-button u-disabled">
                            ${buttonText}
                        </button>
                    </div>
                </div>`,
            );

            const buttonNext = slide.querySelector('.c-quiz__button-next');

            const answerElements = slide.querySelectorAll('.c-quiz__button-answer');

            answerElements.forEach((button) => {
                button.addEventListener('click', () => {
                    buttonNext.classList.remove('u-disabled');
                    // disable all other buttons
                    answerElements.forEach((button) => {
                        button.disabled = true;
                        button.classList.add('u-disabled');
                    });
                    button.style.opacity = 1;

                    // adding background and animation and incrementing counter if correct answer was given
                    if ('correct' in button.dataset) {
                        counter++;
                        button.classList.add('u-background-green');
                        button.classList.add('u-jello-horizontal');
                    } else {
                        button.classList.add('u-background-red');
                        button.classList.add('u-shake-horizontal');

                        // if ShowSolution is true and wrong answer was chosen, the correct one will be highlighted
                        if (showSolution) {
                            answerElements.forEach((button) => {
                                if ('correct' in button.dataset) {
                                    button.classList.add('u-background-green');
                                    button.classList.add('u-jello-horizontal');
                                }
                            });
                        }
                    }
                });
            });

            buttonNext.addEventListener('click', () => {
                this.nextSlide(quizElement, index + 1, questions.length);
                if (index + 1 === questions.length) {
                    this.showResult(quizElement, index + 1, questions.length, counter, resultMessage);
                }
            });

            quizElement.append(slide);
        });

        // add results page at end of quizElement and init "reload quiz" button
        const resultSlide = this.commonMethods.markupToElement(`
            <div class="c-quiz__slide" data-idx="${questions.length}">
                <div class="c-quiz__slide-content">
                    <p class="o-headline--size3">Dein Ergebnis:</p>
                    <p class="c-quiz__result"></p>
                    <button class="c-quiz__button-reload c-button">
                        Quiz neustarten
                    </button>
                </div>
            </div>
        `);

        if (form !== null) {
            this.logger.log('handling quiz with promotion form', quizConfigObject.config.promotion_form);
            // add markup to start promotion
            // <a href="#${quizConfigObject.config.promotion_form.id}" class="c-button c-button--primary c-quiz__button-promotion">
            //     ${quizConfigObject.config.promotion_form.cta_label}
            // </a>
            resultSlide.querySelector('.c-quiz__slide-content').appendChild(this.commonMethods.markupToElement(`
                <div>
                    <h2>${quizConfigObject.config.promotion_form.heading}</h2>
                    <p>${quizConfigObject.config.promotion_form.text}</p>
                    <button class="c-quiz__button-promotion c-button">
                        ${quizConfigObject.config.promotion_form.cta_label}
                    </button>
                </div>
            `));

            resultSlide.querySelector('.c-quiz__button-promotion').addEventListener('click', () => {
                this.nextSlide(quizElement, questions.length + 1, questions.length);
                quizElement.classList.add('has-promotion-shown');
            });
        }

        quizElement.append(resultSlide);
        quizElement.querySelector('.c-quiz__button-reload').addEventListener('click', () => {
            // location.reload();
            this.navigationHandler.refreshCurrentPage(`[data-quizid="${quizConfigObject.id}"]`);
        });

        if (form !== null) {
            const formSlide = this.commonMethods.markupToElement(`
                <div class="c-quiz__slide c-quiz__slide--promotionform" data-idx="${questions.length + 1}"></div>
            `);
            formSlide.appendChild(form);
            form.classList.remove('u-hide');
            quizElement.append(formSlide);
        }

        this.nextSlide(quizElement, 0);
    }

    nextSlide (quizElement, index, questionCount) {
        const previousSlide = quizElement.querySelector('.c-quiz__slide.is-active');
        const nextSlide = quizElement.querySelector(`.c-quiz__slide[data-idx="${index}"]`);

        if (index === questionCount + 1) {
            previousSlide.classList.add('u-fade-out');
        } else {
            previousSlide.classList.add('u-slide-out');
        }
        previousSlide.addEventListener('animationend', () => {
            previousSlide.classList.remove('is-active');
        });

        nextSlide.classList.add('is-active');

        // add countdown if not on result page and maxAnswer time was set
        if (index !== questionCount && this.maxAnswerTime > 0) {
            this.initCountdown(nextSlide, quizElement);
        }
    }

    showResult (quizElement, index, questionCount, counter, resultMessage) {
        const nextSlide = quizElement.querySelector(`.c-quiz__slide[data-idx="${index}"]`);

        resultMessage = resultMessage.replace('CORRECT_ANSWERS', counter).replace('TOTAL_QUESTIONS', questionCount);
        quizElement.querySelector('.c-quiz__result').textContent = resultMessage;

        // show fireworks animation if at least 75% correct
        nextSlide.addEventListener('animationend', () => {
            if (counter / questionCount >= 0.75) {
                this.fireworks();
            }
        });
    }

    initCountdown (nextSlide) {
        const timer = this.commonMethods.markupToElement(`
            <div class="c-quiz__countdown">
                <div class="c-quiz__countdown__number">${this.maxAnswerTime}</div>
                <svg>
                    <circle
                        class="c-quiz__countdown__circle"
                        style="--animation-duration:${this.maxAnswerTime}s;"
                        r="25" cx="30" cy="30">
                    </circle>
                </svg>
                <small class="c-quiz__countdown__text"><small>
            </div>`,
        );
        nextSlide.querySelector('.c-quiz__slide-content').prepend(timer);

        const countdownNumberElement = nextSlide.querySelector('.c-quiz__countdown__number');
        let countdown = this.maxAnswerTime;
        countdownNumberElement.textContent = countdown;

        const colorChange2 = Math.floor(countdown / 3);
        const colorChange1 = Math.floor(countdown / 3 * 2 + 1);

        const questionTimer = setInterval(function () {
            countdown -= 1;
            countdownNumberElement.textContent = countdown;
            if (countdown === colorChange1) {
                nextSlide.querySelector('.c-quiz__countdown__circle').style.stroke =
                    getComputedStyle(document.documentElement).getPropertyValue('--antenne-color-secondary');
            }
            if (countdown === colorChange2) {
                nextSlide.querySelector('.c-quiz__countdown__circle').style.stroke = '#e00000';
            }
            if (countdown === 0) {
                countdownNumberElement.textContent = '';
                clearInterval(questionTimer);
            }
        }, 1000);

        const answerElements = nextSlide.querySelectorAll('.c-quiz__button-answer');
        const questionTimeout = setTimeout(() => {
            const nextButton = nextSlide.querySelector('.c-quiz__button-next');
            if (nextButton) {
                nextButton.classList.remove('u-disabled');
            }
            answerElements.forEach((button) => {
                button.disabled = true;
                button.classList.add('u-disabled');
                nextSlide.querySelector('.c-quiz__countdown__text').textContent = 'Zeit abgelaufen';
            });
        }, this.maxAnswerTime * 1000);

        // stop Countdown when answer clicked
        answerElements.forEach((button) => {
            button.addEventListener('click', () => {
                clearInterval(questionTimer);
                clearTimeout(questionTimeout);
                nextSlide.querySelector('.c-quiz__countdown__circle').style.animationPlayState = 'paused';
            });
        });
    }

    fireworks (duration = 10) {
        duration = duration * 1000;
        const animationEnd = Date.now() + duration;
        const defaults = {
            startVelocity: 10,
            gravity: 0.1,
            spread: 360,
            ticks: 60,
            zIndex: 50,
            disableForReducedMotion: true,
            colors: [
                getComputedStyle(document.documentElement).getPropertyValue('--antenne-color-primary'),
                getComputedStyle(document.documentElement).getPropertyValue('--antenne-color-primary'),
                getComputedStyle(document.documentElement).getPropertyValue('--antenne-color-secondary'),
            ],
        };

        if (this.commonMethods.getTemplateVariation() === 'oldieantenne') {
            defaults.colors = ['#cd007b', '#e2001a', '#ee7f00', '#1fa22e', '#00519e', '#f6c900'];
        }

        function randomInRange (min, max) {
            return Math.random() * (max - min) + min;
        }

        const interval = setInterval(function () {
            const timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) {
                return clearInterval(interval);
            }

            const particleCount = 50 * (timeLeft / duration);
            // since particles fall down, start a bit higher than random
            Confetti(
                Object.assign({},
                    defaults,
                    {
                        particleCount,
                        origin: {
                            x: randomInRange(0.1, 0.3),
                            y: Math.random() - 0.2,
                        },
                    }));
            Confetti(
                Object.assign({},
                    defaults,
                    {
                        particleCount,
                        origin: {
                            x: randomInRange(0.7, 0.9),
                            y: Math.random() - 0.2,
                        },
                    }));
        }, 250);
    }
}

export default Quiz;
