'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('../antenne-frontend')}
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */

import ClassLogger from 'ClassLogger';

class Traffic {
    /**
     * Returns the class name used by the ClassLogger.
     */
    getClassName () {
        return 'Traffic';
    }

    /**
     * @param {CommonMethods} commonMethods
     * @param {NavigationHandler} navigationHandler
     */
    constructor (commonMethods, navigationHandler) {
        this.commonMethods = commonMethods;
        this.navigationHandler = navigationHandler;

        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        this.navigationHandler
            .on('ready', () => this.init())
            .on('render', () => this.init());
    }

    switchVisibility (active, disabled) {
        active.classList.remove('u-hide');
        disabled.classList.add('u-hide');
    }

    switchButtonActiveState (active, disabled) {
        active.classList.add('is-active');
        disabled.classList.remove('is-active');
    }

    async init () {
        this.trafficwrapper = document.querySelector('[data-trafficwrapper]');
        if (!this.trafficwrapper) {
            // sighlently exit here early
            return;
        }

        try {
            this.ensureElementsExists();
        } catch (error) {
            this.logger.error(error);
            return;
        }

        this.addEventListeners();

        // show nearby stuff immediately if location is accessible
        try {
            const browserGeoLocation = await this.commonMethods.getGeolocation({
                askForPermission: false,
                timeout: 2000,
            });
            this.logger.log('Switching to close-to-you tab as geolocation is activated', { browserGeoLocation });
            this.renderNearbyTraffic(browserGeoLocation);
        } catch (error) {
            // Otherwise show all messages
            this.logger.log('Geolocation not available, showing all traffic reports by default');
            this.switchVisibility(this.trafficwrapper, this.nearbyTrafficwrapper);
            this.switchButtonActiveState(this.allTrafficMessagesButton, this.nearbyTrafficMessagesButton);
            this.nearbyTrafficMessagesButton.classList.remove('is-loading');
        }
    }

    ensureElementsExists () {
        // Make sure required elements exist
        if (!this.trafficwrapper) {
            throw new Error('Failed to find the all traffic wrapper');
        }

        this.allTrafficMessagesButton = document.querySelector('[data-trafficbutton="all"]');
        if (!this.allTrafficMessagesButton) {
            throw new Error('Failed to find the button to show all traffic messages');
        }

        this.nearbyTrafficMessagesButton = document.querySelector('[data-trafficbutton="geolocation"]');
        if (!this.nearbyTrafficMessagesButton) {
            throw new Error('Failed to find the button to show nearby traffic messages');
        }

        this.nearbyTrafficwrapper = this.trafficwrapper.parentNode.querySelector('[data-nearbyTrafficwrapper]');
        if (!this.nearbyTrafficwrapper) {
            throw new Error('Failed to find the nearby traffic wrapper');
        }

        this.noGeolocationWarning = this.trafficwrapper.parentNode.querySelector('[data-noGeolocation]');
        if (!this.noGeolocationWarning) {
            throw new Error('Failed to find the no geolocation warning');
        }
    }

    addEventListeners () {
        this.allTrafficMessagesButton.addEventListener('click', (e) => {
            e.target.blur();
            this.switchButtonActiveState(this.allTrafficMessagesButton, this.nearbyTrafficMessagesButton);
            this.switchVisibility(this.trafficwrapper, this.nearbyTrafficwrapper);
            this.noGeolocationWarning.classList.add('u-hide');
        });

        this.nearbyTrafficMessagesButton.addEventListener('click', async (e) => {
            e.target.blur();
            this.renderNearbyTraffic();
        });
    }

    async renderNearbyTraffic (browserGeoLocation = null) {
        this.switchButtonActiveState(this.nearbyTrafficMessagesButton, this.allTrafficMessagesButton);
        if (
            this.nearbyTrafficwrapper.innerHTML.includes('is-skeleton') === false &&
            this.nearbyTrafficwrapper.textContent.trim().length > 0
        ) {
            this.logger.log('nearby was already renderd, using existing tab');
            this.switchVisibility(this.nearbyTrafficwrapper, this.trafficwrapper);
            return;
        }

        let shouldAddLoadingIndicator = true;
        setTimeout(() => {
            if (shouldAddLoadingIndicator) {
                this.nearbyTrafficMessagesButton.classList.add('is-loading');
            }
        }, 300);

        try {
            if (browserGeoLocation === null) {
                browserGeoLocation = await this.commonMethods.getGeolocation({ askForPermission: false });
            }
            const browserGeoLocationLat = browserGeoLocation.coords.latitude;
            const browserGeoLocationLong = browserGeoLocation.coords.longitude;

            this.trafficwrapper.classList.add('u-hide');

            this.noGeolocationWarning.classList.add('u-hide');

            this.logger.log('Filtering for nearby traffic items', { browserGeoLocation });

            const start = performance.now();
            let inDistance = 0;
            let outOfDistance = 0;

            const nearbyTrafficItems = Array
                .from(this.trafficwrapper.querySelectorAll('[data-coords]'))
                .map(item => {
                    const [lat, long] = item.getAttribute('data-coords').split(',');
                    const dist = this.distanceInKm(lat, long, browserGeoLocationLat, browserGeoLocationLong);

                    // we only need to clone if its in range
                    if (dist <= 100) {
                        inDistance++;
                        const trafficItem = item.cloneNode(true);
                        const street = item.getAttribute('data-street');
                        // if street starts with A or B and second char is a number we add it to the strong element
                        if ((/^[AB]\d/.test(street)) && item.getAttribute('data-id')) {
                            const strongElement = trafficItem.getElementsByTagName('strong')[0];
                            if (strongElement) {
                                strongElement.textContent = `${strongElement.textContent.trim()}, ${street}`;
                            }
                        }
                        return {
                            trafficItem,
                            distanceInKm: dist,
                        };
                    }

                    outOfDistance++;
                    return null;
                })
                .filter(item => item)
                .sort((a, b) => a.distanceInKm - b.distanceInKm)
                .map(item => item.trafficItem);

            const end = performance.now();
            this.logger.log('In distance of 100km are ' + inDistance + '/' +
                outOfDistance + ' reports, took: ' + Math.round(end - start) + ' ms');
            this.nearbyTrafficwrapper.innerHTML = '';
            if (nearbyTrafficItems.length > 0) {
                this.nearbyTrafficwrapper.append(...nearbyTrafficItems);
            } else {
                this.nearbyTrafficwrapper.innerHTML = `
                    <p>Keine Meldungen in deiner Nähe gefunden.</p>
                `;
            }
        } catch (error) {
            this.logger.error('Failed to retrieve users geolocation', error);
            this.noGeolocationWarning.classList.remove('u-hide');
            this.nearbyTrafficwrapper.innerHTML = '';
        }

        this.switchVisibility(this.nearbyTrafficwrapper, this.trafficwrapper);
        shouldAddLoadingIndicator = false;
        this.nearbyTrafficMessagesButton.classList.remove('is-loading');
    }

    /**
     * Using haversine (https://en.wikipedia.org/wiki/Haversine_formula)
     * algorithm this computes the distance between variable coordinates against
     * a fixed coordinate.
     *
     * @returns {number}
     *
     *  We may checkout this here for super performance
     *  https://jamesloper.com/fastest-way-to-calculate-distance-between-two-coordinates
     */
    distanceInKm (lat1, lon1, fixedLat, fixedLon) {
        if (lat1 === fixedLat && lon1 === fixedLon) {
            return 0;
        }

        // make sure this is computed just once, as it won't change with our usage
        if (typeof this.fixedRadLat === 'undefined') {
            this.fixedRadLat = rad(fixedLat);
            this.fSin = Math.sin(this.fixedRadLat);
            this.fCos = Math.cos(this.fixedRadLat);
        }

        const radlat1 = rad(lat1);
        const radtheta = rad(lon1 - fixedLon);
        const dist = Math.min(
            1, Math.sin(radlat1) * this.fSin + Math.cos(radlat1) * this.fCos * Math.cos(radtheta),
        );
        return Math.acos(dist) * 6370.693485653;
    }
}

function rad (degree) {
    return 0.01745329251 * degree;
}

export default Traffic;
