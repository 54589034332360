'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./CommonMethods').default} CommonMethods
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */

import ClassLogger from 'ClassLogger';

class BrushHeadlines {
    /**
     * Returns the log prefix.
     */
    getClassName () {
        return 'BrushHeadlines';
    }

    /**
     * Create a new instance.
     *
     * @param {CommonMethods} commonMethods
     * @param {NavigationHandler} navigationHandler
     */
    constructor (commonMethods, navigationHandler) {
        this.commonMethods = commonMethods;
        this.navigationHandler = navigationHandler;

        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        if (document.documentElement.classList.contains('template--rockantenne') === false) {
            return;
        }

        this.navigationHandler
            .on('ready', () => {
                this.assignBrushBackgroundToHeadlines();
                this.registerOnWindowResizeListener();
            })
            .on('render', () => {
                this.assignBrushBackgroundToHeadlines();
            });
    }

    /**
     * Register a `resize` listener on the window that re-renders the brush
     * background for selected headlines when the browser window is resized.
     *
     * @returns {this}
     */
    registerOnWindowResizeListener () {
        this.logger.log('Registering window "resize" listener');

        window.addEventListener('resize', () => {
            this.commonMethods.debounce(() => {
                this
                    .removeBrushBackgroundFromHeadlines()
                    .assignBrushBackgroundToHeadlines();
            }, 200)();
        });

        return this;
    }

    /**
     * Render brush backgrounds for individual headlines and make sure
     * multiline headlines receive their own brush backgrounds.
     */
    assignBrushBackgroundToHeadlines () {
        const headlines = this.headlinesRequiringBrushBackground();

        this.logger.log(`Adding brush background to ${headlines.length} headline(s)`);

        headlines.forEach(headline => {
            this.addBrushBackgroundTo(headline);
        });
    }

    /**
     * Returns all headlines that should have a brush background.
     *
     * @returns {NodeList[]}
     */
    headlinesRequiringBrushBackground () {
        return document.querySelectorAll('[data-brush]');
    }

    /**
     * The process to add brush backgrounds is the following:
     *
     *   1. wrap each word of the headline in a <span> tag
     *   2. go through each word (wrapped in a span) of the headline and concat those words on the same row
     *   3. join all words in the same row and wrap that text in a <span> that carries the brush background
     *   4. assign the created HTML for the headline to the given `element`. And we’re done.
     *
     * @param {Element} element
     */
    addBrushBackgroundTo (element) {
        const words = Array.from(element.innerHTML.split(/\s+/))
            .filter(word => word.trim() !== '');
        element.innerHTML = '';

        const lines = new Map();
        let line = 1;

        let span = document.createElement('span');
        span.classList.add('o-brushbg');
        let previousOffset = 0;
        words.forEach((word, index) => {
            let lineItems = lines.get(line) || [];
            lineItems.push(word);
            lines.set(line, lineItems);
            span.innerHTML = `<span>${lineItems.join(' ')}</span>`;
            element.appendChild(span);
            const currentOffset = span.offsetHeight;
            if (index === 0) {
                previousOffset = currentOffset;
            } else if (currentOffset > previousOffset) {
                lineItems.pop();
                lines.set(line, lineItems);
                span.innerHTML = `<span>${lineItems.join(' ')}</span>`;
                element.appendChild(span);
                span = document.createElement('span');
                span.classList.add('o-brushbg');
                lineItems = lines.get(++line) || [];
                lineItems.push(word);
                lines.set(line, lineItems);
                span.innerHTML = `<span>${lineItems.join(' ')}</span>`;
                element.appendChild(span);
                previousOffset = span.offsetHeight;
            }
        });
    }

    /**
     * Remove the brush background from selected headlines. Removing it
     * from those currently having a brush background assigned.
     *
     * @returns {this}
     */
    removeBrushBackgroundFromHeadlines () {
        this.headlinesRequiringBrushBackground().forEach(headline => {
            this.removeBrushBackgroundFrom(headline);
        });

        return this;
    }

    /**
     * Removing the brush background for the given `element` is done by
     * retrieving the content of the <span> wrapper which carries the
     * brush background. Then assign only the content to the element.
     *
     * @param {Element} element
     *
     * @returns {this}
     */
    removeBrushBackgroundFrom (element) {
        let content = '';

        element.querySelectorAll('.o-brushbg span').forEach((span) => {
            content = content.concat(span.innerHTML).concat(' ');
        });

        if (content) {
            element.innerHTML = content.trim();
        }

        return this;
    }
}

export default BrushHeadlines;
