/**
 * TrackingService
 *
 * @version 1.0.0
 * @copyright 2022 SEDA.digital GmbH & Co. KG
 *
 * @typedef {import('./CommonMethods').default} commonMethods
 */

'use strict';

import ClassLogger from 'ClassLogger';
import ConsentMissingError from './ConsentMissingError';
import { ApiClient } from './ApiClient';
import Mutex from './Mutex';

export default class TrackingService {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'TrackingService';
    }

    /**
     * @param {CommonMethods} commonMethods
     */
    constructor (commonMethods) {
        this.commonMethods = commonMethods;
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        /** @private */
        this.apiClient = new ApiClient();
        this.identCookieName = window.antenne.config.cookie_ident || window.antenne.config.contextkey + '-ident';
        this.identifiersMutex = new Mutex();

        // as a fallback, we are using a profile id (profile with supa consent) for google
        try {
            const userId = this.getProfileId();
            if (userId !== null) {
                this.setGoogleUserId(userId);
            }
        } catch (error) {
            this.logger.error('Could not set google user id', error);
        }

        window.antenneTracking = {
            track: (...args) => this.track(...args),
            getProfileId: (...args) => this.getProfileId(...args),
        };
    }

    async getIdentTokenFromCookie () {
        const token = this.commonMethods.getCookie(this.identCookieName);
        if (token === null) {
            throw new Error('No cookie found');
        }
        return token;
    }

    async decodeIdentToken (token) {
        const jwtPayload = token.split('.')[1] || '';
        const payload = JSON.parse(this.commonMethods.b64DecodeUnicode(jwtPayload));
        if (typeof payload !== 'object') {
            throw new Error('Failed to decode tracking token');
        }
        return payload;
    }

    // method is called from within our NavigationHandler
    async sendIdentifiers () {
        await this.identifiersMutex.lock();
        try {
            const token = await this.getIdentTokenFromCookie();
            const payload = await this.decodeIdentToken(token);

            if (window.nativeJsBridge.isWebview) {
                // We are in a webview - we call the JS-Bridge and delete the cookie
                window.nativeJsBridge.callHandler('identtoken.set', {
                    identtoken: token,
                    nbf: payload.nbf,
                    profile_id: payload.sub,
                });
                this.commonMethods.deleteCookie(
                    this.identCookieName,
                    '/',
                    window.location.host.replace('www.', '.'),
                );
                return;
            }

            await this.commonMethods.waitForExplicitConsent();

            // Check consent for station legal entity (cmp vendor name and station legal entity string MUST match)
            const service = window.UC_UI.getServicesBaseInfo()
                .filter(service => service.name === window.antenne.config.station.legal_entity);
            if (service.length !== 1) {
                this.logger.error('No cmp vendor for current station found', {
                    station_name: window.antenne.config.station.legal_entity,
                });
                return;
            }
            if (service[0].consent.status !== true) {
                throw new ConsentMissingError(`Missing consent for station vendor "${service[0].id}"`, {
                    templateId: service[0].id,
                });
            }

            if ((payload.nbf * 1000) > Date.now()) {
                this.logger.log('nbf not reached for ping', {
                    nbf: new Date(payload.nbf * 1000),
                    now: new Date(),
                    payload,
                });
                return;
            }

            this.logger.log('Cookie found', payload);

            const result = await this.apiClient.post('/profile/identifiers', {
                identifiers: [
                    ...await this.getAllAvailableIdentifiers(),
                    { type: 'tcf-string', identifier: await this.commonMethods.getTcString() },
                    { type: 'usercentrics-controllerid', identifier: window.UC_UI.getControllerId() },
                ],
            });
            this.logger.log('Ping delivered. Next: ' + new Date((result.nbf || 0) * 1000), result);
        } catch (error) {
            if (error.message === 'No cookie found') {
                this.logger.log('No ping delivered – no token found');
            } else {
                this.logger.error('Error during ping delivery', error);
            }
        } finally {
            this.identifiersMutex.unlock();
        }
    }

    /**
     * Called from User.js
     * @param {object} userObject
     */
    setLoggedInUser (userObject) {
        this.setGoogleUserId(userObject.id);
    }

    setGoogleUserId (id) {
        if (typeof window.gtag === 'function') {
            this.commonMethods.hash(id, 'SHA-256').then((hashedId) => {
                this.logger.log('Setting hashed id', { hashedId });
                window.gtag('set', {
                    user_id: hashedId,
                });
            }).catch((error) => {
                this.logger.error('Could not set google user id', error);
            });
        }
    }

    /**
     * Track the given `event` with the provided `data`.
     *
     * @param {string} event
     * @param {*} payload
     */
    track (event, payload = {}) {
        // Google Analytics
        if (typeof window.gtag === 'function') {
            try {
                const clonedPayload = { ...payload };
                const googleAnalyticsEvent = this.quantyooEventToGoogleEvent(event, clonedPayload);
                if (
                    typeof googleAnalyticsEvent === 'object' &&
                    googleAnalyticsEvent.event &&
                    googleAnalyticsEvent.payload
                ) {
                    this.logger.log('Tracking GA event', googleAnalyticsEvent);
                    window.gtag('event', googleAnalyticsEvent.event, googleAnalyticsEvent.payload);
                }
            } catch (error) {
                this.logger.warn('Google Analytics tracking error', { error });
            }
        }
    }

    quantyooEventToGoogleEvent (event, payload) {
        const map = {
            'fix:view': (payload) => {
                return false;
            },
            'fix:view:unload': (payload) => {
                return false;
            },
            'fix:media:start': (payload) => {
                return false;
            },
            'fix:media:cancel': (payload) => {
                return false;
            },
            'fix:media:complete': (payload) => {
                return false;
            },
        };
        if (map[event]) {
            return map[event](payload);
        }
        if (payload.occurredon) {
            delete payload.occurredon;
        }
        return { event: event.replace('fix:', '').replace(':', '_'), payload };
    }

    getUserAgent () {
        return navigator.userAgent;
    }

    async getAllAvailableIdentifiers () {
        const self = this;
        const identifiers = [];

        await Promise.allSettled([
            self.getAdswizzListenerId(),
        ]).then((results) => {
            results.forEach((promise) => {
                if (promise.status === 'fulfilled') {
                    identifiers.push({
                        identifier: promise.value.identifier,
                        type: promise.value.type,
                    });
                }
            });
        });

        self.logger.log('getAllAvailableIdentifiers result:', identifiers);
        return identifiers;
    }

    async getAdswizzListenerId () {
        const self = this;
        const identifierType = 'adswizz-listenerid';
        const adswizzTcfVendorId = 507;

        const consents = await this.commonMethods.getTcfConsents([adswizzTcfVendorId]);
        if (consents[adswizzTcfVendorId] !== true) {
            throw new ConsentMissingError(`Missing consent for "AdsWizz" vendor "${adswizzTcfVendorId}"`, {
                templateId: adswizzTcfVendorId,
            });
        }

        if (self._AdswizzListenerIdRequest !== undefined) {
            return self._AdswizzListenerIdRequest;
        }

        self._AdswizzListenerIdRequest = new Promise((resolve, reject) => {
            if (
                typeof window.com_adswizz_synchro_listenerid === 'string' &&
                window.com_adswizz_synchro_listenerid !== ''
            ) {
                self.logger.log(`Got ${identifierType} from existing global var`, {
                    com_adswizz_synchro_listenerid: window.com_adswizz_synchro_listenerid,
                });
                self._storeIdentifier(identifierType, window.com_adswizz_synchro_listenerid);
                resolve({ type: identifierType, identifier: window.com_adswizz_synchro_listenerid });
                return;
            }

            self.logger.log('Requesting AdsWizz ListenerId from adswizz.com');
            self.commonMethods.loadScript('https://synchrobox.adswizz.com/register2.php')
                .then(() => {
                    if (
                        typeof window.com_adswizz_synchro_listenerid !== 'string' ||
                        window.com_adswizz_synchro_listenerid === ''
                    ) {
                        throw new Error(`No ${identifierType} received from adswizz.com`);
                    }

                    if (window.com_adswizz_synchro_listenerid === 'optout') {
                        throw new Error(`User has an opt-out for ${identifierType}`);
                    }

                    self.logger.log(`Received ${identifierType} from adswizz.com`, {
                        com_adswizz_synchro_listenerid: window.com_adswizz_synchro_listenerid,
                    });
                    resolve({ type: identifierType, identifier: window.com_adswizz_synchro_listenerid });
                })
                .catch((error) => {
                    reject(error);
                });
        });

        self._AdswizzListenerIdRequest.then(
            (identifier) => self.logger.log(`${identifierType}:`, identifier),
            (error) => self.logger.warn(`${identifierType}: ${error}`),
        );

        return self._AdswizzListenerIdRequest;
    }

    async getProfileId () {
        try {
            return (await this.decodeIdentToken(await this.getIdentTokenFromCookie())).sub || null;
        } catch (error) {
            this.logger.log('Error getting profileId from ident token', error);
            return null;
        }
    }

    async loadEmetriq () {
        try {
            await this.commonMethods.waitForExplicitConsent();
            const tcfVendorId = 213;

            const consents = await this.commonMethods.getTcfConsents([tcfVendorId]);
            if (consents[tcfVendorId] !== true) {
                throw new ConsentMissingError(`Missing consent for "emetriq" vendor "${tcfVendorId}"`, {
                    templateId: tcfVendorId,
                });
            }

            window._enqAdpParam = window._enqAdpParam || {};
            const sid = window._enqAdpParam.sid || false;
            if (!sid) {
                throw new Error('No "sid" found for ematriq.');
            }

            try {
                const listenerid = await this.getAdswizzListenerId();
                window._enqAdpParam.rmsid = listenerid.identifier;
            } catch (error) {
                this.logger.info('Could not add listenerid for emetriq', error);
            }

            try {
                const profileId = await this.getProfileId();
                if (profileId !== null) {
                    window._enqAdpParam.id_abyprofile = profileId;
                }
            } catch (error) {
                this.logger.info('Could not add profile id for emetriq', error);
            }

            const emetriqScript = document.createElement('script');
            emetriqScript.id = 'emetriq';
            emetriqScript.setAttribute('src', `https://ups.xplosion.de/loader/${sid}/default.js`);
            emetriqScript.async = true;
            emetriqScript.onload = () => {
                this.logger.log('emetriq script loaded', { _enqAdpParam: window._enqAdpParam });
            };
            document.head.appendChild(emetriqScript);
        } catch (error) {
            this.logger.error('Error during emetriq handling', error);
        }
    }
}
