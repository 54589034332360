/**
 * Ads / Reklame
 *
 * @version 1.0.0
 * @copyright 2024 SEDA.digital GmbH & Co. KG
 *
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 * @typedef {import('./CommonMethods').default} CommonMethods
 * @typedef {import('./Emitter').default} EventEmitter
 * @typedef {import('./TrackingService').default} TrackingService
 * @typedef {import('./User').default} User
*/

'use strict';

import ClassLogger from 'ClassLogger';
import ConsentMissingError from './ConsentMissingError';
import { gte as semVerGte } from 'semver';
import { ApiClient } from './ApiClient';

export default class Reklame {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'Reklame';
    }

    /**
     * @param {CommonMethods} commonMethods
     * @param {NavigationHandler} navigationHandler
     * @param {EventEmitter} eventEmitter
     * @param {TrackingService} trackingService
     * @param {User} userService
     */
    constructor (commonMethods, navigationHandler, eventEmitter, trackingService, userService) {
        this.commonMethods = commonMethods;
        this.navigationHandler = navigationHandler;
        this.eventEmitter = eventEmitter;
        this.trackingService = trackingService;
        this.userService = userService;
        /** @private */
        this.apiClient = new ApiClient();
        this.abortController = new AbortController();
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        const host = window.antenne.config.production_host || window.location.host;
        const hostParts = host.split('.').slice(-2);
        this.domain = hostParts[0];
        this.tld = hostParts[1];

        const params = new URLSearchParams(document.location.search);
        if (params.get('debugAds') === '1') {
            window.document.documentElement.classList.add('debug-ads');
        }

        // When consent for Symplr is revoked, we must remove uuSymplr
        this.clearPPIDOnMissingConsent();

        setTimeout(() => {
            this.loadSymplrScript()
                .then(() => { this.init(); })
                .catch(async (error) => {
                    this.logger.error('Error during symplr script load', error);
                    await this.prepareFallbacks(this.abortController.signal);

                    this.navigationHandler.on('ready', () => {
                        this.fillFallbacks(this.abortController.signal);
                    });
                    this.navigationHandler.on('render', () => {
                        this.fillFallbacks(this.abortController.signal);
                    });
                });
        }, 0);
    }

    async prepareFallbacks (signal) {
        this.logger.log('Rendering Reklame Fallbacks');
        try {
            this.templateId = await this.getTemplateId();
        } catch (error) {
            this.logger.error('Failed to retrieve template ID:', error.message);
        }
        this.fallbacks = await this.apiClient.get('/reklame/fallbacks', { credentials: 'omit', signal });
        if (signal.aborted) {
            return;
        }
        this.logger.log('Got fallbacks', this.fallbacks);
    }

    /**
     * @returns {Promise<number>}
     */
    async getTemplateId () {
        const element = document.querySelector('.l-page-content');
        if (!element) {
            throw new Error('Element with selector `.l-page-content` not found');
        }

        const templateId = parseInt(element.dataset.tpl || null);
        if (isNaN(templateId)) {
            throw new Error(`'data-tpl' attribute is not a integer: '${templateId}'`);
        }

        return templateId;
    }

    async fillFallbacks (signal) {
        this.logger.log('Rendering Reklame Fallbacks');

        const fallbackChannels = Array.from(this.fallbacks.channels);
        const fallbackStreamingCards = Array.from(this.fallbacks.streaming);
        let fallbackCards = Array.from(this.fallbacks.cards);
        fallbackCards = fallbackCards.filter(card => !card.includes(window.location.host + window.location.pathname));
        this.findSlots().forEach(slot => {
            if (signal.aborted) {
                return;
            }

            slot.classList.add('is-fallback');
            slot.classList.remove('is-filled');

            if (slot.offsetParent === null) {
                // slot is hidden (display=none) -> skip
                this.logger.log('Slot ' + slot.dataset.slotid + ' is hidden.', slot);
                return;
            }

            if (slot.dataset.slotid.startsWith('incontentsmall_')) {
                return;
            }

            if (fallbackCards.length === 0) {
                return;
            }

            const randomIndex = Math.floor((Math.random() * fallbackCards.length));
            let fallback = fallbackCards.splice(randomIndex, 1)[0];
            fallback = fallback.replace('c-card ', 'c-card c-card--reklame ');

            if (slot.dataset.slotid === 'bb_1' && fallbackChannels.length > 0) {
                fallback = fallback.replace('c-card ', 'c-card u-show-medium ');
                const randomIndex = Math.floor((Math.random() * fallbackChannels.length));
                const fallbackChannel = fallbackChannels.splice(randomIndex, 1)[0]
                    .replace('c-card--audio ', 'c-card--audio u-hide-medium ');
                fallback = fallback + fallbackChannel;
            }

            if (this.templateId === 3 && fallbackStreamingCards.length > 0) {
                const randomStreamingIndex = Math.floor((Math.random() * fallbackStreamingCards.length));
                fallback = fallbackStreamingCards.splice(randomStreamingIndex, 1)[0];
                fallback = fallback.replace('c-card ', 'c-card c-card--reklame ');
                this.logger.log('Use streaming fallback ad');
            }
            slot.innerHTML = `<div class="o-reklame__fallbackwrapper">${fallback}</div>`;
            slot.classList.add('is-filled');
        });
    }

    async init () {
        this.logger.log(`Running init for ${this.domain}.${this.tld}`);
        this.logger.log('Ready to init Reklame slots');

        this.navigationHandler.on('before-render', () => {
            this.logger.log('Aborting Reklame rendering... due to page navigation');
            this.abortController.abort();
            try {
                this.logger.log('Destroying slots and re-setting inline styles');
                window.destroySlotsSymplr();
                this.findSlots().forEach(slot => {
                    slot.removeAttribute('style');
                });
            } catch (error) {
                this.logger.error('Failed to destroy slots', error);
            }
            this.abortController = new AbortController();
        });

        this.navigationHandler.on('ready', () => {
            this.logger.log('Initial slot rendering');
            this.findAndBuildSlots(this.abortController.signal);
        });
        this.navigationHandler.on('render', () => {
            this.logger.log('Rendering slots after navigation');
            this.findAndBuildSlots(this.abortController.signal);
        });
    }

    async findAndBuildSlots (signal) {
        try {
            if (signal.aborted) {
                return;
            }

            const slots = this.findSlots();
            if (signal.aborted) {
                return;
            }

            await this.ensureSymplrReady();
            if (signal.aborted) {
                return;
            }

            await this.buildSlots(slots);
        } catch (error) {
            this.logger.error('Error during Reklame rendering', error);
        }
    }

    findSlots () {
        const slots = Array.from(document.querySelectorAll('.o-reklame[data-slotid]'));
        this.logger.log('Identified ' + slots.length + ' slots', { slots });
        return slots;
    }

    /**
     * @param {Array<Element>} slots
     */
    async buildSlots (slots) {
        this.hasRenderedOnce = true;
        slots.forEach(slot => {
            if (slot.offsetParent === null) {
                // slot is hidden (display=none) -> skip
                this.logger.log('Slot ' + slot.dataset.slotid + ' is hidden.', slot);
                return;
            }
            this.logger.log('Building slot ' + slot.dataset.slotid);

            const fullSlotId = `${this.domain}.${this.tld}_${slot.dataset.slotid}`;
            slot.innerHTML = `
                <span class="o-reklame__label">Anzeige</span>
                <div id="${fullSlotId}"></div>
            `;
            slot.setAttribute('aria-hidden', 'true');
            slot.setAttribute('tabindex', '-1');
            try {
                window.buildSlotsSymplr(fullSlotId);
            } catch (error) {
                this.logger.error('Symplr Error for ' + slot.dataset.slotid, error);
                slot.classList.add('has-error');
                slot.dataset.error = error.name;
            }
        });
    }

    async ensureSymplrReady () {
        if (typeof window.symplrSlotsReady !== 'function') {
            throw new Error('`symplrSlotsReady` is not a function');
        }

        const ready = await window.symplrSlotsReady();
        if (ready !== true) {
            throw new Error('Reklame could not be initialized (not true)');
        }

        if (typeof window.buildSlotsSymplr !== 'function') {
            throw new Error('`buildSlotsSymplr` is not a function');
        }

        if (typeof window.destroySlotsSymplr !== 'function') {
            throw new Error('`destroySlotsSymplr` is not a function');
        }

        return ready;
    }

    async loadSymplrScript () {
        this.logger.log('Ensuring cmp consent…');

        await this.ensureSymplrConsent();

        if (window.nativeJsBridge.isWebview) {
            const appVersion = await window.nativeJsBridge.callHandler('appVersion');
            if (typeof appVersion !== 'string') {
                throw new TypeError('Received appVersion is not a string value');
            }
            if (semVerGte(appVersion, '5.0.29') === false) { // due to a flutter-webview bug (allowinlineplayback)
                throw new Error(`Version constraint not met. We expected version greater or equal
                    5.0.29 but received ${appVersion}`);
            }
        }

        await this.setPPID();

        this.logger.log('Loading symplr script…');
        return new Promise((resolve, reject) => {
            const scriptElement = document.createElement('script');
            scriptElement.id = 'symplr';
            scriptElement.setAttribute('src', `https://cdns.symplr.de/${this.domain}.${this.tld}/${this.domain}.js`);
            scriptElement.defer = true;
            scriptElement.onload = () => {
                this.logger.log('symplr script loaded');
                resolve();
            };
            scriptElement.onerror = (error) => {
                reject(error);
            };
            document.head.appendChild(scriptElement);

            const hint = document.createElement('link');
            hint.rel = 'preconnect';
            hint.as = 'document';
            hint.href = 'https://cockpit.symplr.de';
            hint.crossorigin = true;
            document.head.appendChild(hint);
        });
    }

    async ensureSymplrConsent () {
        await this.commonMethods.waitForExplicitConsent();
        const cmpServiceTemplateId = '4hxLtM08VF9C2l';
        const consents = await this.commonMethods.getConsents([cmpServiceTemplateId]);
        if (consents.length !== 1 || consents[0].consent.status !== true) {
            throw new ConsentMissingError(`Missing consent for "symplr" vendor "${cmpServiceTemplateId}"`, {
                templateId: cmpServiceTemplateId,
            });
        }
    }

    async setPPID () {
        let id = null;
        try {
            // get account id from logged-in user
            const user = await this.userService.getUser();
            id = user.id;
            this.logger.log('uuSymplr: Using account id', { id });
        } catch (error) {
        }

        if (id === null) {
            // fall back to profile Id from user with supadupa consent
            try {
                id = await this.trackingService.getProfileId();
                this.logger.log('uuSymplr: Using profile id', { id });
            } catch (error) {
            }
        }
        if (id === null) {
            await this.ensureSymplrConsent();
            // fallback to random id
            if (window.localStorage.getItem('uuSymplr')) {
                id = window.localStorage.getItem('uuSymplr');
                this.logger.log('uuSymplr: Using stored id', { id });
            } else {
                if (window.crypto && window.crypto.randomUUID) {
                    id = window.crypto.randomUUID();
                } else {
                    id = Date.now() + window.navigator.userAgent + window.antenne.config.station.stationkey;
                    id = await this.commonMethods.hash(id, 'SHA-256');
                }
                this.logger.log('uuSymplr: Generated new id', { id });
                window.localStorage.setItem('uuSymplr', id);
            }
            // when we are using a fallback id and consent is revoked, the id must be deleted from storage
            this.eventEmitter.on('uc_consent_change', () => {
                this.clearPPIDOnMissingConsent();
            });
            this.eventEmitter.on('tcf_consent_change', () => {
                this.clearPPIDOnMissingConsent();
            });
        }

        // Hash the ID
        id = await this.commonMethods.hash(id, 'SHA-256');
        if (!id.match(/^[0-9a-zA-Z+.=/_\-$,{}]{22,150}$/)) {
            throw new Error('Hashed PPID does not match regex');
        }

        this.logger.log('Setting window.uuSymplr to ' + id);
        window.uuSymplr = id;
    }

    async clearPPIDOnMissingConsent () {
        try {
            await this.ensureSymplrConsent();
        } catch (error) {
            this.logger.log('Removing uuSymplr from storage');
            window.localStorage.removeItem('uuSymplr');
        }
    }
}
